
import React from 'react';
import { Layout } from "antd";
import Bpa from './components/bpa'

import Order from './components/order'
import Login from './pages/login/login'
import Franchisee from './pages/franchisee/franchisee'
import Statistics from './pages/statistics/statistics'
import DeviceBom from './pages/deviceBom/deviceBom';
import { Route, BrowserRouter, Routes, Outlet,  Navigate } from 'react-router-dom'
import { observer, inject } from "mobx-react"
import LoadingCard from './components/LoadingCard/loading';
import './App.css';

const BeforeRouter = () => {
  let token = localStorage.getItem("userToken");
  let isAgree = false
  if (token)
    isAgree = true
  return isAgree ? <Bpa></Bpa> : <Navigate to='/login' />
}
const App = inject('store')(observer((props) => {
  const { globalStore } = props.store;
  const { Content } = Layout;


  return (
    <div className="App" >
      {
        globalStore.isLoading ? <LoadingCard></LoadingCard> : null
      }
      <Outlet />

      <Layout >
        <Content >
          <React.Fragment>
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<Navigate to='/login' />}></Route>
                <Route path='/bpa' element={<BeforeRouter></BeforeRouter>}></Route>
                <Route path='/ordercall' element={<Order></Order>}></Route>
                <Route path='/login' element={<Login></Login>} ></Route>
                <Route path='/franchisee' element={<Franchisee></Franchisee>} ></Route>
                <Route path='/statistics' element={<Statistics ></Statistics>} ></Route>
                <Route path='/deviceBom' element={<DeviceBom ></DeviceBom>} ></Route>
                <Route path='*' element={<Navigate to='/login' />}></Route>

              </Routes>

            </BrowserRouter>

          </React.Fragment>

        </Content>

      </Layout>

    </div>

  )
}));


export default App;
