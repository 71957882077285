import { Spin, Space } from 'antd';
import './loading.css';


const Loading = () => {
    return (
        <div className='loading-card'>
            <Space size="middle">
                <Spin size="large" className='loading-iconfont' />
            </Space>
        </div>
    )
}

export default Loading;