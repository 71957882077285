import { observable } from 'mobx';

const globalStore = observable({
    isLoading: false,
    toLoadingTrue(){
        this.isLoading = true;
    },
    toLoadingFalse(){
        this.isLoading = false;
    }
})

export default globalStore;