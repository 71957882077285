import http from '../utils/httpRequst/http_axios';
import { message } from 'antd';
// import/no-anonymous-default-export
//eslint-disable-next-line
export default {
	userLogin(param) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/login', param).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//根据加盟商ID获取 门店列表
	getOrgList(UuerId) {
		return new Promise((resolve, reject) => {
			http("get", '/kitchen/api/StoreHelper/GetOrgList?UuerId=' + UuerId).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//根据门店ID获取 设备列表
	geDeviceList(StoreId) {
		return new Promise((resolve, reject) => {
			http("get", '/kitchen/api/StoreHelper/GeDeviceList?StoreId=' + StoreId).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//根据设备ID、配方 统计信息
	getDeviceRecipe(param) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/StoreHelper/GetDeviceRecipe', param).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//根据设备ID、材料 统计信息
	getDeviceRecipeBom(param) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/StoreHelper/GetDeviceRecipeBom', param).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//获取组织架构
	getOrgTree(uerId) {
		return new Promise((resolve, reject) => {
			http("get", '/kitchen/api/StoreHelper/getOrgTree?uerId=' + uerId).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},
	//根据组织Id获取配方信息
	getOrgRecipe(param) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/StoreHelper/GetOrgRecipe', param).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//根据组织ID获取材料信息
	getOrgRecipeBom(param) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/StoreHelper/GetOrgRecipeBom', param).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//根据组织ID获取配方列表
	getFormulaList(param) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/Device/GetDeviceBOM', param).then(res => {
				resolve(res);
			}, error => {
				console.log('>>>>', error);
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//获取组织架构：组织+设备
	getDeviceOrg() {
		return new Promise((resolve, reject) => {
			http("get", '/kitchen/api/Device/StoreDeviceTree?isTMC=true').then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//根据门店/设备ID获取实时库存分页
	GetDeviceTMCStorePage(param) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/Device/GetDeviceTMCStorePage', param).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//告诉后台即将调获取实时库存接口
	CheckStockTMCAdjust(deviceId) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/Device/CheckStockTMCAdjust?DeviceId=' + deviceId).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//同步库存原料
	PushDeviceTMC(deviceId) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/Device/PushDeviceTMC?DeviceId=' + deviceId).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//查询上架配方列表
	GetShelfFormulaList(param) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/bom/getpage', param).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	},

	//上架配方
	AddDeviceBOM(param) {
		return new Promise((resolve, reject) => {
			http("post", '/kitchen/api/Device/AddDeviceBOM', param).then(res => {
				resolve(res);
			}, error => {
				message.info("网络异常~", error);
				reject(error)
			});
		});
	}
}