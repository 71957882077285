import './franchisee.css';
import React, { useState, useEffect } from 'react';
import logoPng from "../../assets/images/favicon.png";
import analysisPng from "../../assets/images/analysis.png";
import formulaPng from "../../assets/images/formula.png";
import { toTree } from '../../utils/common/common'
import { message } from 'antd';
import tasteCubeAPI from "../../api/tasteCube";
import { useNavigate } from "react-router-dom";
import { Popconfirm } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';

const Franchisee = () => {

    const navigate = useNavigate();
    const [tree, setTree] = useState([
        {
            title: ''
        }
    ]);

    const onGetOrgTree = async () => {
        const user = JSON.parse(localStorage.getItem('userToken'));
        const response = await tasteCubeAPI.getOrgTree(user.userID);
        if (response.length > 0) {
            const tree = toTree(response)
            setTree(JSON.parse(JSON.stringify(tree)));
            localStorage.setItem('originData', JSON.stringify(response));
            localStorage.setItem('tree', JSON.stringify(tree));
        } else {
            message.error(response.msg || '获取组织架构出错');
        }
    }

    const onNavigateAnalysis = () => {
        navigate(`/statistics?orgId=${tree[0].key}&orgName=${tree[0].title}&isStore=${false}`);
    }

    const onNavigateFormula = () => {
        navigate(`/deviceBom`);
    }

    useEffect(() => {
        onGetOrgTree();
    }, []);

    return (
        <div className="franchisee-container">
            <div className="franchisee-head">
                <div className="franchisee-work-title">工作台</div>
                <div className='back-tabs'>
                    <Popconfirm
                        title="确定要退出登录吗？"
                        onConfirm={() => {
                            navigate('/login');
                            localStorage.clear();
                        }}
                        okText="确定"
                        cancelText="取消"
                    >
                        <PoweroffOutlined />
                    </Popconfirm>
                </div>
                <div className="franchisee-head-desc">
                    <div className="franchisee-desc-title">
                        <span>味魔方连锁口味管理</span>
                        <img src={logoPng} alt="logo" />
                    </div>
                    <div className="franchisee-desc-content">
                        实现业务互联互通、信息共享和资源协调，助力经营管理效能最大化。
                    </div>
                </div>
            </div>
            <div className="franchisee-btn-list">
                <div className="franchisee-btn-item" onClick={onNavigateFormula}>
                    <div className="franchisee-btn-cover">
                        <img src={formulaPng} alt="logo"></img>
                    </div>
                    <div className="franchisee-btn-name">
                        配方管理
                    </div>
                    <div className="franchisee-btn-desc">
                        增强市场响应速度
                    </div>
                </div>
                <div className="franchisee-btn-item" onClick={onNavigateAnalysis}>
                    <div className="franchisee-btn-cover">
                        <img src={analysisPng} alt="logo"></img>
                    </div>
                    <div className="franchisee-btn-name">
                        配方分析
                    </div>
                    <div className="franchisee-btn-desc">
                        发现最新的消费需求
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Franchisee;