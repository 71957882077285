/* eslint-disable */
import './deviceBom.css';
import React, { useEffect, useState } from 'react';
import { Cascader, Popup } from 'antd-mobile';
import { useNavigate } from "react-router-dom";
import { DownOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, message, Button } from 'antd';
import tasteCubeAPI from "../../api/tasteCube";
import { treeTransList } from "../../utils/common/common";

const columns = [
    {
        title: '配方名称',
        dataIndex: 'name',
        key: 'name'
    }
]

// 库存表头
const stockColums = [
    {
        title: '物料',
        dataIndex: 'batching_Name',
        key: 'batching_Name'
    },
    {
        title: '数量',
        dataIndex: 'batching_Count',
        key: 'batching_Count'
    },
    {
        title: '库位',
        dataIndex: 'batching_Loc',
        key: 'batching_Loc'
    }
];

//上架配方表头
const shelfFormulaColums = [
    {
        title: '配方名称',
        dataIndex: 'name',
        key: 'name'
    }
]

const DeviceBom = () => {
    const [showOrgList, setShowOrgList] = useState(false);                  //是否展示组织列表
    const [orgList, setOrgList] = useState([]);                             //组织架构列表
    const [currentOrg, setCurrentOrg] = useState({ title: '' });            //当前选中的组织架构
    const [formulaList, setFormulaList] = useState([]);                     //配方列表
    const [total, setTotal] = useState(0);                                  //总页数
    const [currentPage, setCurrentPage] = useState(1);                      //当前页码
    const [pageSize, setPageSize] = useState(10);                           //每页显示条数
    const [showStock, setShowStock] = useState(false);                      //库存弹窗
    const [stockList, setStockList] = useState([]);                         //库存列表
    const [stockTotal, setStockTotal] = useState(0);
    const [stockCurrentPage, setStockCurrentPage] = useState(1);
    const [stockPageSize, setStockPageSize] = useState(10);
    /** 上架配方 */
    const [showShelfFormula, setShowShelfFormula] = useState(false);
    const [shelfFormulaList, setShelfFormulaList] = useState([]);
    const [shelfFormulaTotal, setShelfFormulaTotal] = useState(0);
    const [shelfFormulaCurrentPage, setShelfFormulaCurrentPage] = useState(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const navigate = useNavigate();

    //获取组织架构
    const onGetStoreDeviceTree = async () => {
        const response = await tasteCubeAPI.getDeviceOrg();
        if (response.statusCode === 200) {
            const orgTree = toCascaderDataType(response.data);
            setCurrentOrg(orgTree[0]);
            setOrgList(orgTree);
        } else {
            message.error(response.errors || '获取组织架构失败')
        }
    }

    //根据当前组织查询配方列表
    const onQueryFormulaList = async () => {
        const jsonData = {
            DeviceId: currentOrg.key,
            current: currentPage,
            pageSize: pageSize,
        }
        if (currentOrg.type === 'org') {
            jsonData.type = 'org';
        } else {
            jsonData.type = 'device';
        }
        const response = await tasteCubeAPI.getFormulaList(jsonData);
        if (response.statusCode === 200) {
            setFormulaList(response.data.data);
            setTotal(response.data.total);
        } else {
            message.error(response.errors || '查询配方列表失败');
        }
    }

    const onCurrentPageChange = (value) => {
        setCurrentPage(value);
    }

    const toCascaderDataType = (originData) => {
        originData.forEach(item => {
            item.label = item.title;
            if (item.children && item.children.length > 0) {
                toCascaderDataType(item.children);
            } else {
                delete item.children;
            }
        });
        return originData;
    }

    //查询设备库存管理列表
    const onStockAdmin = async () => {
        if (!currentOrg.key) return;
        const resAdjust = await tasteCubeAPI.CheckStockTMCAdjust(currentOrg.key);
        if (resAdjust.data) {
            const jsonData = {
                DeviceId: currentOrg.key,
                current: stockCurrentPage,
                pageSize: stockPageSize,
            }
            if (currentOrg.type === 'org') {
                jsonData.type = 'org';
            } else {
                jsonData.type = 'device';
            }
            const response = await tasteCubeAPI.GetDeviceTMCStorePage(jsonData);
            if (response.statusCode === 200) {
                setStockTotal(response.data.total);
                setStockList(response.data.data);
            } else {
                message.error(response.errors || '查询设备库存失败');
            }
        } else {
            message.error(resAdjust.errors || '获取实时库存失败，请重试！');
        }
    }

    //设备库存管理分页事件
    const onStockCurrentPageChange = (value) => {
        setStockCurrentPage(value);
    }

    //同步库存原料
    const onPushDeviceTMC = async () => {
        const response = await tasteCubeAPI.PushDeviceTMC(currentOrg.key);
        if (response.statusCode === 200) {
            message.success('同步成功');
            setShowStock(false);
            onQueryFormulaList();
        } else {
            message.error(response.errors || '同步库存原料失败');
        }
    }

    //上架配方页码改变
    const onShelfFormulaCurrentPageChange = (value) => {
        setShelfFormulaCurrentPage(value);
    }

    //上架配方列表
    const onQueryShelfFormulaList = async () => {
        const jsonData = {
            current: shelfFormulaCurrentPage,
            pageSize: 5000,
            pertains: [2]
        }
        const response = await tasteCubeAPI.GetShelfFormulaList(jsonData);
        if (response.statusCode === 200) {
            setShelfFormulaTotal(response.data.total);
            setShelfFormulaList(response.data.data);
        } else {
            message.error(response.errors || '获取上架配方列表失败');
        }
    }

    //上架配方多选
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    //确定上架
    const onAddDeviceBOM = async () => {
        const Boms = [];
        selectedRowKeys.forEach(item => {
            Boms.push({
                BOMId: item
            });
        });
        const jsonData = {
            Boms,
            DeviceId: currentOrg.key
        }
        if (currentOrg.type === 'org') {
            jsonData.type = 'org';
        } else {
            jsonData.type = 'device';
        }
        const response = await tasteCubeAPI.AddDeviceBOM(jsonData);
        if (response.statusCode === 200) {
            message.success('上架成功');
            setShowShelfFormula(false);
            onQueryFormulaList();
        } else {
            message.error(response.errors || '上架失败，请重试');
        }
    }

    useEffect(() => {
        onGetStoreDeviceTree();
    }, []);

    useEffect(() => {
        onQueryFormulaList();
    }, [currentOrg, currentPage]);

    useEffect(() => {
        onStockAdmin();
    }, [stockCurrentPage]);

    useEffect(() => {
        onQueryShelfFormulaList();
    }, [shelfFormulaCurrentPage]);
    return (
        <div className="device-bom-container">
            <div className="statistics-head-card">
                <div className="statistics-head" >
                    <LeftOutlined onClick={() => { navigate(-1) }} />
                    <div className='back-head-card' onClick={() => { setShowOrgList(true); }}>
                        {currentOrg.title}<DownOutlined style={{ marginLeft: '0.2rem' }} />
                    </div>
                </div>
                <div className="statistics-table-head">
                    <Button className="statistics-head-btn" type="primary" icon={<PlusOutlined />} onClick={() => { setShowShelfFormula(true); onQueryShelfFormulaList(); }}>上架配方</Button>
                    <Button danger className="statistics-head-btn" type="primary" onClick={() => { onStockAdmin(); setShowStock(true); }}>设备库存管理</Button>
                </div>
            </div>
            <Table className="device-bom-table" size="small" rowKey={record => record.id} columns={columns} dataSource={formulaList} pagination={{ total, current: currentPage, pageSize, onChange: onCurrentPageChange }} />
            <Cascader
                options={orgList}
                visible={showOrgList}
                onClose={() => {
                    setShowOrgList(false);
                }}
                onConfirm={v => {
                    const floatArrat = treeTransList(JSON.parse(JSON.stringify(orgList)), 'children');
                    const findOrg = floatArrat.find(item => item.key === v[v.length - 1]);
                    if (findOrg) {
                        setCurrentOrg(findOrg);
                    }
                }}
            />
            <Popup
                visible={showStock}
                onMaskClick={() => {
                    setShowStock(false)
                }}
                bodyStyle={{ height: '100vh', overflow: 'auto' }}
            >
                <div className="stock-admin-btns">
                    <LeftOutlined onClick={() => setShowStock(false)} />
                    <div>
                        <Button className="stock-btn-item" onClick={onStockAdmin}>获取实时库存</Button>
                        <Button className="stock-btn-item" onClick={onPushDeviceTMC}>同步库存原料</Button>
                    </div>
                </div>
                <Table rowKey={record => record.id} size="small" columns={stockColums} dataSource={stockList} pagination={{ total: stockTotal, current: stockCurrentPage, pageSize: stockPageSize, onChange: onStockCurrentPageChange }} />
            </Popup>
            <Popup
                visible={showShelfFormula}
                bodyStyle={{ height: '100vh', overflow: 'auto' }}
            >
                <div className="stock-admin-btns">
                    <LeftOutlined onClick={() => setShowShelfFormula(false)} />
                    <Button className="stock-btn-item" onClick={onAddDeviceBOM}>确定上架</Button>
                    <div className="stock-admin-title">
                        上架配方
                    </div>
                </div>
                <Table size="small" rowKey={record => record.id} rowSelection={rowSelection} columns={shelfFormulaColums} dataSource={shelfFormulaList} pagination={{ total: shelfFormulaTotal, current: shelfFormulaCurrentPage, pageSize: 5000, onChange: onShelfFormulaCurrentPageChange }} />
            </Popup>
        </div>
    )
}

export default DeviceBom;