import http from '../utils/httpRequst/http_axios'
import { message,Spin   } from 'antd';
import moment from 'moment'
function getTempleData(){
    return new Promise((resolve, reject) => {
        http("get",'/order/api/system/description').then(res => {
          resolve (res);
        },error => {
          message.info("网络异常~",error);
          reject(error)
        })
      }) 
}

// function getFoodsMeunByTypeid(storeTypeid){
//   return new Promise((resolve, reject) => {
//     http("post",`/api/store/goodsstoretypelist/${storeTypeid}`).then(res => {
//       resolve (res);
//     },error => {
//       message.info("网络异常~",error);
//       reject(error)
//     })
//   }) 
// }

function getFoodsMeunByid(storeid){
    Spin.setDefaultIndicator(<div></div>)
  return new Promise((resolve, reject) => {
    http("get",`/kitchen/api/StoreHelper/StoreGoodsMenuByStoreId?storeId=${storeid}`).then(res => {
        console.log(res);
      resolve (res);
    },error => {
      message.info("网络异常~",error);
      reject(error)
    })
  }) 
}
function getStoreList() {
    return new Promise((resolve, reject) => {
        http("get",`/kitchen/api/StoreHelper/GetAllStore`).then(res => {
          resolve (res);
        },error => {
          message.info("网络异常~",error);
          reject(error)
        })
      }) 
}

function getStoreByStoreId(id){
  return new Promise((resolve, reject) => {
    http("get",`/kitchen/api/StoreHelper/GetStoreInfoById/?storeId=${id}`).then(res => {
      resolve (res);
    },error => {
      message.info("网络异常~",error);
      reject(error)
    })
  }) 
}

function placeOrder(param){
  return new Promise((resolve, reject) => {
    http("post",'/order/order/admin/neworder',param).then(res => {
      resolve (res);
    },error => {
      message.info("网络异常~",error);
      reject(error)
    })
  }) 
} 

function getOrderlist(pageindex=0,status=0,ipadid){
 var jsondata;
 if (status==='-3') {
  jsondata = {
    "pageSize": 6,
    "pageIndex": 0,
    "subOrder": '',
    "show": true,
    "userId": ipadid,
    "createAt": moment().format('YYYY-MM-DD')
  }
 }else{
  jsondata ={
    "pageSize": 6,
    "pageIndex": pageindex,
    "userId": ipadid,
    "subOrder": status,
    "createAt":  moment().format('YYYY-MM-DD')
}
}
  // console.log('123123123123123',jsondata);
  // param = JSON.parse(jsondata);
  return new Promise((resolve, reject) => {
    http("post",'/order/order/admin/fullorderbyadmin',jsondata).then(res => {
      resolve (res);
    },error => {
      message.info("网络异常~",error);
      reject(error)
    })
  }) 
}

function refund(ordernumber){
  return new Promise((resolve, reject) => {
    http("get",`/order/order/admin/refundwithnorefunc/${ordernumber}`).then(res => {
      resolve (res);
    },error => {
      message.info("网络异常~",error);
      reject(error)
    })
  }) 
}


function appOrderCount(mystoreid,mystoretypeid){
  var jsondata = {
  "storeTypeId": mystoretypeid,
  "storeId": mystoreid,
  "orderTime": ""
  }
  return new Promise((resolve, reject) => {
    http("post",`/kitchen/api/Order/AppOrderCount`,jsondata).then(res => {
      resolve (res);
    },error => {
      message.info("网络异常~",error);
      reject(error)
    })
  }) 
}


function guid() {
  function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

export{
    getTempleData,
    getFoodsMeunByid,
    getStoreByStoreId,
    getStoreList,
    placeOrder,
    getOrderlist,
    refund,
    appOrderCount,
    guid
}