import CryptoJS from "crypto-js";

const gloableData = {
    TenantId: '',
    AppId: '',
}

function cryptoAes(word) {
    let keyStr = 'bpa20210418bpa20210418bpa*******';
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key,
        {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
    return encrypted.ciphertext.toString().toUpperCase();
}

function cryptoDec(encrypted) {
    let keyStr = 'bpa20210418bpa20210418bpa*******';
    const decipher = CryptoJS.AES.decrypt(encrypted, keyStr, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.ZeroPadding,
        iv: '',
    });
    console.log('我是介绍说三', decipher);
    // 将解密对象转换成 UTF8 的字符串
    const resultDecipher = CryptoJS.enc.Utf8.stringify(decipher.toString());
    return resultDecipher;
}

function getkcard(url, name) {
    var reg = new RegExp('(^|&|/?)' + name + '=([^&|/?]*)(&|/?|$)', 'i')
    var r = url.substr(1).match(reg)
    if (r != null) {
        return r[2]
    }
    return null;
}
// 生成树状
function toTree(data) {
    data.forEach(function (item) {

        delete item.children;
    });
    var map = {};
    data.forEach(function (item) {
        map[item.key] = item;

    });
    var val = [];
    data.forEach(function (item) {
        item.label = item.title;
        item.value = item.key;
        var parent = map[item.parentId];
        if (parent) {
            (parent.children || (parent.children = [])).push(item);
        } else {
            val.push(item);
        }
    });

    return val;
}

//树形转扁平
function treeTransList(tree, childName = 'children') {
    // 设置临时数组，用来存放队列
    let queen = [];
    // 设置输出数组，用来存放要输出的一维数组
    const result = [];
    queen = queen.concat(tree);
    // 对树对象进行广度优先的遍历
    while (queen.length) {
        const first = queen.shift();
        if (first[childName]) {
            queen = queen.concat(first[childName]);
            delete first[childName]
        }
        result.push(first);
    }
    return result;
}

export {
    getkcard,
    cryptoAes,
    cryptoDec,
    toTree,
    treeTransList,
    gloableData
}